<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-select
          v-decorator="['key']"
          class="sm-select"
          placeholder="用户类型筛选"
        >
          <a-select-option value="0"> 会员 </a-select-option>
          <a-select-option value="1"> 后台用户 </a-select-option>
          <a-select-option value="2"> 游客 </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-range-picker
          :disabled-date="disabledDate"
          @change="onChange"
          :locale="locale"
          v-decorator="['date']"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          清空
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import { NoteModel } from "../../models/note";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import userNameDisplay from "../../utils/userNameDisplay";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
const noteModel = new NoteModel();
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

const columns = [
  {
    title: "序列号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "用户类型",
    dataIndex: "user_type",
    key: "user_type",
  },
  {
    title: "用户账号",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "ip地址",
    dataIndex: "ip",
    key: "ip",
  },
  {
    title: "网站类型",
    dataIndex: "web_type",
    key: "web_type",
  },
  {
    title: "事件名称",
    dataIndex: "event_name",
    key: "event_name",
  },
  {
    title: "事件内容",
    dataIndex: "event_content",
    key: "event_content",
  },
  {
    title: "事件时间",
    dataIndex: "created_at",
    key: "created_at",
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      loading: false,
      total: 0,
      locale,
      date1: "",
      date2: "",
      current: 1,
      form2: this.$form.createForm(this, { name: "coordinated" }),
    };
  },

  created() {
    this.queryNoteList();
  },

  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },

    onChange(date, dateString) {
      this.date1 = dateString[0];
      this.date2 = dateString[1];
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryNoteList(values.key, this.date1, this.date2, 1);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryNoteList();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryNoteList(values.key, this.date1, this.date2, page);
        }
      });
    },

    queryNoteList(key, date1, date2, page) {
      this.loading = true;
      noteModel.index(key, date1, date2, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            if (item.user_type == "admin") {
              item.user_type = "后台用户";
            } else if (item.user_type == "member") {
              item.user_type = "会员";
            } else {
              item.user_type = "游客";
            }

            if (item.user) {
              item.user_name = userNameDisplay(item.user);
            } else if (item.admin) {
              item.user_name = item.admin.admin_name;
            } else {
              item.user_name = "游客";
            }
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
import {HTTP} from '../utils/axios'

class NoteModel extends HTTP {
  /*首页渲染*/
  index(key = '', date1 = '', date2 = '', page = 1) {
    return this.request({
        url: 'board/system_notes',
        method: "GET",
        data: {
            key: key,
            date1: date1,
            date2: date2,
            page: page
        }
    })
  }
}

export {NoteModel}